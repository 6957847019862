(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@clappr/player"));
	else if(typeof define === 'function' && define.amd)
		define(["@clappr/player"], factory);
	else if(typeof exports === 'object')
		exports["PlaybackRatePlugin"] = factory(require("@clappr/player"));
	else
		root["PlaybackRatePlugin"] = factory(root["Clappr"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__92__) {
return 